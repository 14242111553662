import {
  useCreatePathologyDetail,
  useUpdatePathologyDetail,
} from '../../../../_hooks/pathology-detail/use-pathology-details';
import {useFormik} from 'formik';
import {useEffect} from 'react';
import {Form, Select, TextField} from '../../../_ui/form';
import pathologyDetailFormValidationSchema from './validation-schema';
import Button from '../../../_ui/button';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {usePathologies} from '../../../../_hooks/pathology/use-pathologies';
import {ROUTES} from '../../../../router/routes';

const PathologyDetailForm = ({pathologyDetail, onChange}) => {
  const {t} = useTranslation();
  const {updatePathologyDetail} = useUpdatePathologyDetail();
  const {createPathologyDetail} = useCreatePathologyDetail();
  const {pathologies} = usePathologies({filters: {pathology_detail_id: [pathologyDetail.id]}})

  const { setValues, dirty, handleChange, handleSubmit, handleReset, values, errors, isValid, touched, handleBlur } = useFormik({
    initialValues: pathologyDetailFormValidationSchema.cast(pathologyDetail),
    onSubmit: (formValues) => {
      if (formValues.id) {
        updatePathologyDetail(formValues);
      } else {
        createPathologyDetail(formValues);
        handleReset({});
      }
      setTimeout(() => onChange?.(), 200);
    },
    validationSchema: pathologyDetailFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  useEffect(() => {
    setValues(pathologyDetailFormValidationSchema.cast(pathologyDetail))
  }, [pathologyDetail]);

  return (
    <Form>
      <input type="hidden" name="id" value={values.id}/>
      <Select
        required
        name="category"
        label={"Catégorie"}
        value={values.category || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.category && !!errors.category}
        errorMessage={errors.category?.toString()}
      >
        <option disabled value="">Choisir une catégorie</option>
        {['event', 'symptom_reproduction', 'positive_test', 'relevant_observation'].map((category, index) => (
          <option key={index} value={category}>
            {t(`enums.pathology_detail.category.${category}`)}
          </option>
        ))}
      </Select>
      <TextField
        required
        type="text"
        name="text"
        label={"Label"}
        placeholder={"Label"}
        smallVerticalPadding
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.text || ''}
        hasError={touched.text && !!errors.text}
        errorMessage={errors.text?.toString()}
      />
      {pathologies?.list.length > 0 && (
        <div
          style={{
            color: '#ffa100',
            width: '100%',
            textAlign: 'left',
          }}
        >
          <h3>Les pathologies suivantes seront impactées par ces changements&nbsp;:</h3>
          <ul>
            {pathologies?.list.map((pathology, index) => (
              <li key={index}>
                <a href={ROUTES.PATHOLOGIES(pathology.id)} target="_blank">
                  {pathology.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Button
        disabled={!dirty || !isValid}
        onClick={(e) => {
          handleSubmit();
          e.preventDefault();
        }}
      >
        {values.id ? "Modifier" : "Créer"}
      </Button>
    </Form>
  );
};

export default PathologyDetailForm;