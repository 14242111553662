import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const BottomBarContainer = styled.div`
  display: flex;
  height: 76px;
  padding: 12px 32px 22px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--XL, 32px) var(--XL, 32px) 0px 0px;
  border-top: 1px solid var(--Neutral-Grey-80, #EDEDED);
  border-right: 1px solid var(--Neutral-Grey-80, #EDEDED);
  border-left: 1px solid var(--Neutral-Grey-80, #EDEDED);
  background: var(--Background-surface-0, #FFF);
  /* Drop Shadow/xl */
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
`;

const NavLinkStyled = styled(NavLink)`
  box-sizing: border-box;
  color: #D9D9D9;
  
  &.active {
    color: #1D1D1B;
  }
`;

const BottomBar = ({items, ...rest}) => (
  <BottomBarContainer {...rest}>
    {items.map((item, index) => (
      <NavLinkStyled
        key={index}
        to={item.external ? {pathname: item.link} : item.link}
        end
        target={item.target}
        rel={item.target === '_blank' ? 'noreferrer' : ''}
        onClick={e => item.onClick && item.onClick(e)}
        style={item.style}
      >
        {item.Icon && (
          <item.Icon
            width={item?.iconSize?.width || 40}
            height={item?.iconSize?.height || 40}
          />
        )}
      </NavLinkStyled>
    ))}
  </BottomBarContainer>
);

export default BottomBar