import {useAuthContext} from '../../../../_contexts/auth';
import styled from 'styled-components';
import Monogram from '../../../_ui/monogram';
import {ReactComponent as ExpandCircleRightIcon} from '../../../../_icons/expand_circle_right.svg';
import {ReactComponent as CheckCircleIcon} from '../../../../_icons/check_circle.svg';
import {ReactComponent as ToCloseIcon} from '../../../../_icons/to_close.svg';
import {ReactComponent as PendingIcon} from '../../../../_icons/pending.svg';
import {ReactComponent as ThumbDownIcon} from '../../../../_icons/thumb_down.svg';
import {ReactComponent as ThumbUpIcon} from '../../../../_icons/thumb_up.svg';
import {ReactComponent as RecentPatientIcon} from '../../../../_icons/recent_patient.svg';
import {ROUTES} from '../../../../router/routes';
import {useNavigate} from 'react-router-dom';
import {TextField} from '../../../_ui/form';
import {useEffect, useState} from 'react';
import {DiagnosticAPIService} from '../../../../_services/api';
import {APIListPayload, APIListResponse} from '../../../../_services/api/_helpers/api-list-types';
import {APIDiagnostic} from '../../../../_services/api/_helpers/api-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: calc(100svh - 76px);
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  padding: 8px 20px;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  
  h1 {
    font-size: 24px;
  }
`;

const Bubble = styled.div`
  display: flex;
  padding: 0.8px 6px 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: 1px solid var(--Colors-Primary-Eerie-Black-P, #1D1D1B);
  background: var(--Secondary-Lemon-SLe, #E6F728);
  box-shadow: 0.5px 0.5px 0px 0px #000;
  position: absolute;
  left: -3px;
  top: -3px;
  color: var(--Text-primary, #1D1D1B);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 118.182% */
  letter-spacing: -0.2px;
`;

const Avatar = ({text, imageUrl, count}) => {
  const navigate = useNavigate();

  return (
    <div style={{position: 'relative'}}>
      <Monogram
        style={{cursor: 'pointer'}}
        onClick={() => navigate(ROUTES.PROFILES('me'))}
        $backgroundImageUrl={imageUrl}
      >
        {imageUrl ? null : text}
      </Monogram>
      <Bubble>{count}</Bubble>
    </div>
  )
};

const Card = styled.div<{$borderStyle?: string}>`
  display: flex;
  height: 130px;
  min-height: 130px;
  padding: 20px 12px 12px 20px;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 32px;

  border: 1px ${props => props.$borderStyle} var(--Primary-Black-P-60, #DCDCD9);
  background: var(--Background-surface-0, #FFF);

  box-sizing: border-box;
`;

const CardsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--4, 16px);
  align-self: stretch;
  overflow-y: scroll;

  box-sizing: border-box;
  
  > :last-child {
    margin-bottom: 20px;
  }
`;

const Caption = styled.div<{$bgColor: string; $txtColor: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--Rounded, 99999px);
  background: ${props => props.$bgColor};
  color: ${props => props.$txtColor};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
  padding: 2px 2px 2px 8px;
  gap: 2px;
  
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  //height: 718px;
  padding: 0 20px 0 20px;

  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Label = styled.div`
  color: var(--Primary-Black-P, var(--Colors-Primary-Eerie-Black-P, #1D1D1B));
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/Title3 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.2px;
`;

const DateStyled = styled.div`
  color: var(--Text-primary-light, #636360);
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/Callout */
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.2px;
`;

const statusesAssociations = {
  created: {
    bgColor: '#1D1D1B',
    txtColor: '#FFF',
    text: 'En cours',
    Icon: PendingIcon,
    borderStyle: 'dashed',
  },
  filtered: {
    bgColor: '#1D1D1B',
    txtColor: '#FFF',
    text: 'En cours',
    Icon: PendingIcon,
    borderStyle: 'dashed',
  },
  to_close: {
    bgColor: '#E6F728',
    txtColor: '#1D1D1B',
    text: 'À Clôturer',
    Icon: ToCloseIcon,
    borderStyle: 'solid',
  },
  closed: {
    bgColor: '#ADE82F',
    txtColor: '#1D1D1B',
    text: 'Clôturé',
    Icon: CheckCircleIcon,
    borderStyle: 'solid',
  },
}

const StatusCaption = ({status}) => {
  const Icon = statusesAssociations[status].Icon;

  return (
    <Caption
      $bgColor={statusesAssociations[status].bgColor}
      $txtColor={statusesAssociations[status].txtColor}
    >
      {statusesAssociations[status].text}
      <Icon/>
    </Caption>
  );
};

const Tab = styled.div<{$active?: boolean;}>`
  color: ${props => props.$active ? '#1D1D1B' : '#B8B8B8'};
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--2, 8px);
  flex: 1 0 0;
  
  &::after {
    content: ' ';
    height: 3px;
    width: 10px;
    background-color: ${props => props.$active ? '#1D1D1B' : 'none'};
    border-radius: 2px;
  }
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
`;

const tabsSettings = [
  {
    label: "Dates",
    payload: {
      sorting: [
        {
          name: 'diagnostic_versions_created_at',
          direction: 'desc',
        },
      ],
    },
  },
  {
    label: "Statut",
    payload: {
      sorting: [
        {
          name: 'diagnostic_versions_status',
          direction: 'asc',
        },
        {
          name: 'diagnostic_versions_created_at',
          direction: 'asc',
        },
      ],
    },
  },
  {
    label: "A - Z",
    payload: {
      sorting: [
        {
          name: 'label',
          direction: 'asc',
        },
        {
          name: 'diagnostic_versions_created_at',
          direction: 'asc',
        },
      ],
    },
  },
  {
    label: "Feedback",
    payload: {
      filters: {
        diagnostic_versions_status: ['closed'],
      },
      sorting: [
        {
          name: 'diagnostic_versions_created_at',
          direction: 'desc',
        },
      ],
    },
  },
];

const DiagnosticCard = ({diagnostic, headerStyle = undefined, ...rest}) => {
  const navigate = useNavigate();
  const diagnosticVersion = diagnostic.diagnostic_versions[0];
  const date = new Date(diagnosticVersion.created_at);
  const dateFormatted = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`;
  return (
    <Card
      onClick={() => navigate(
        (diagnosticVersion.status === 'created' && !diagnosticVersion.quick)
          ? ROUTES.DIAGNOSTIC_PATHOLOGY_DETAILS(diagnostic.id)
          : ROUTES.DIAGNOSTIC_RESULT(diagnostic.id)
      )}
      $borderStyle={statusesAssociations[diagnosticVersion.status].borderStyle}
      {...rest}
    >
      <CaptionContainer style={headerStyle}>
        <Label>
          {diagnostic.label || `Diagnostic du ${dateFormatted}`}
        </Label>
        <DateStyled>
          {dateFormatted}
        </DateStyled>
      </CaptionContainer>
      <CaptionContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <StatusCaption status={diagnosticVersion.status}/>
          {diagnosticVersion.feedback === 'bad' && (
            <ThumbDownIcon
              width={24}
              height={24}
              style={{
                display: 'flex',
                padding: 3,
                alignItems: 'flex-start',
                gap: 10,
                borderRadius: 9999,
                backgroundColor: '#1D1D1B',
                boxSizing: 'border-box',
              }}
            />
          )}
          {diagnosticVersion.feedback === 'good' && (
            <ThumbUpIcon
              width={24}
              height={24}
              style={{
                display: 'flex',
                padding: 3,
                alignItems: 'flex-start',
                gap: 10,
                borderRadius: 9999,
                backgroundColor: '#1D1D1B',
                boxSizing: 'border-box',
              }}
            />
          )}
          {diagnosticVersion.quick && <RecentPatientIcon style={{alignSelf: 'flex-end', color: '#1D1D1D'}}/>}
        </div>
        <div>
          <ExpandCircleRightIcon/>
        </div>
      </CaptionContainer>
    </Card>
  );
};

const DiagnosticsPage = () => {
  const {user} = useAuthContext();
  const [diagnostics, setDiagnostics] = useState<APIListResponse<APIDiagnostic>>();
  const [payload, setPayload] = useState<APIListPayload>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const results = await DiagnosticAPIService.getDiagnostics(payload);
      setDiagnostics(results);
      setIsLoading(false);
    })();
  }, [payload]);

  useEffect(() => {
    setPayload(tabsSettings[currentTab].payload as APIListPayload);
  }, [currentTab]);

  return (
    <Container>
      <Header>
        <div style={{flex: 1}}>
          <h1>Ton Historique</h1>
        </div>
        <Avatar
          text={user.first_name.charAt(0)}
          imageUrl={user.avatar?.url || ''}
          count={user.balance}
        />
      </Header>
      <ContentContainer>
        <TextField
          name={'search'}
          placeholder={'Rechercher'}
          onChange={event => {
            setPayload(prev => ({...prev, filters: {...prev.filters, label: [event.target.value]}}));
          }}
        />
        <Tabs>
          {tabsSettings.map((tabSettings, index) => (
            <Tab
              key={index}
              onClick={() => setCurrentTab(index)}
              $active={index === currentTab}
            >
              {tabSettings.label}
            </Tab>
          ))}
        </Tabs>
        <CardsContainer>
          {
            !isLoading && diagnostics?.list?.map((diagnostic, index) => (
              <DiagnosticCard key={index} diagnostic={diagnostic}/>
            ))
          }
        </CardsContainer>
        {/*<div style={{position: 'relative', width: '100%', height: 0}}>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      position: 'absolute',*/}
        {/*      top: -50,*/}
        {/*      left: 0,*/}
        {/*      right: 0,*/}
        {/*      width: '100%',*/}
        {/*      height: 50,*/}
        {/*      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFFAA 100%)',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </ContentContainer>
    </Container>
  );
};

export default DiagnosticsPage;

export {
  DiagnosticCard,
};