import {PinturaEditor} from '@pqina/react-pintura';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import '@pqina/pintura/pintura.css';
import locale_fr_FR, {
  MarkupEditor as locale_markup_editor_fr_FR,
} from '@pqina/pintura/locale/fr_FR/core/fr_FR.js';

// Import the editor functionality
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_annotate,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_annotate_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor
  markup_editor_defaults,
  createMarkupEditorToolStyles,
} from '@pqina/pintura';

const penColorRGBA = [0.678, 0.91, 0.18, 1];
const colorComparisonErrorGap = 3;// 3 of 255 -> ~1%
const penColorRGBAWithErrorGap = penColorRGBA.map(value => value * 255 - colorComparisonErrorGap);

// This registers the plugins with Pintura Image Editor
setPlugins(plugin_annotate);

// Create our editor configuration
const editorConfig = (width, height) => ({
  zoomMaskOpacity: 0,
  // This will read the image data (required)
  imageReader: createDefaultImageReader(),

  // This will write the output image
  imageWriter: createDefaultImageWriter({
    format: 'canvas',
    targetSize: {
      width: width / 4,
      height: height / 4,
      fit: 'contain',
      upscale: false,
    },
  }),

  // The markup editor default options, tools, shape style controls
  ...markup_editor_defaults,

  // This handles complex shapes like arrows / frames
  shapePreprocessor: createDefaultShapePreprocessor(),

  // The icons and labels to use in the user interface (required)
  locale: {
    ...locale_en_gb,
    ...locale_fr_FR,
    ...plugin_crop_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
    ...locale_markup_editor_fr_FR,
  },
});
const stringifyImageState = (imageState) => {
  return JSON.stringify(imageState, (k, v) => (v === undefined ? null : v));
};

const Human = forwardRef(({imageStateEditor, bodyPart, disabled = false}:any, ref) => {
  const [brushWidthInternal, setBrushWidthInternal] = useState(40);
  const componentRef = useRef(null);

  const undo = () => {
    const { editor } = componentRef.current;

    editor.history.undo();
  };
  const redo = () => {
    const { editor } = componentRef.current;

    editor.history.redo();
  };
  const clear = () => {
    const { editor } = componentRef.current;

    editor.history.revert();
  };
  const processImage = async () => {
    const { editor } = componentRef.current;

    const imageState = await editor.processImage();
    const canvas = imageState.dest;
    const imageStateStr = stringifyImageState(imageState.imageState);
    return {
      binary_area: generateReducedBytes(canvas)?.toString(),
      image_state_editor: JSON.parse(imageStateStr),
    };
  };

  const onLoad = async () => {
    if (!imageStateEditor) {
      return ;
    }
    const { editor } = componentRef.current;

    editor.history.write(imageStateEditor);
  };

  const generateReducedBytes = (canvas) => {
    if (canvas) {
      const bytes = canvas?.getContext('2d')?.getImageData(0,0, canvas.width, canvas.height)?.data;
      if (bytes) {
        const reducedBytes = new Uint8Array(bytes.length / 4 / 8);
        let array32 = new Uint32Array(bytes.length / 4);

        for (let i = 0; i < bytes.length; i += 4) {
          array32[i / 4] = +[0, 1, 2, 3].every(index => bytes[i + index] >= penColorRGBAWithErrorGap[index]);
        }

        for (let i = 0; i < reducedBytes.length; i++) {
          reducedBytes[i] = [...Array(8)].reduce(
            (prev, _, index) => prev + (+(array32[8 * i + index] > 0) << (8 - index - 1)),
            0
          );
        }
        return reducedBytes;
      }
    }
  };

  useImperativeHandle(ref,() => {
    return {
      setBrushWidth: setBrushWidthInternal,
      // compare,
      saveItem: generateReducedBytes,
      clear,
      undo,
      redo,
      processImage,
    };
  }, []);

  const toolStyles = useMemo(() => (
    createMarkupEditorToolStyles({sharpie: {strokeWidth: `${1+brushWidthInternal/100*5}%`, strokeColor: penColorRGBA}})
  ), [brushWidthInternal]);

  return (
    <PinturaEditor
      {...editorConfig(bodyPart.width, bodyPart.height)}
      ref={componentRef}
      enableCanvasAlpha={true}
      enableUtils={false}
      enableToolbar={false}
      src={`/images/human/${bodyPart.label}.png`}
      markupEditorToolbar={[
        ['sharpie', 'Sharpie', {disabled: false}],
        ['eraser', 'Eraser', {disabled: false}],
      ]}
      onLoad={onLoad}
      markupEditorToolStyles={toolStyles}
      disabled={disabled}
      panLimitGutterScalar={1}
      enablePanLimit={false}
    />
  );
});

export default Human;