import {PageContainer} from '../../../_ui/page';
import TabbedNavigation from '../../../_ui/tabbed-navigation';
import {useState} from 'react';
import {useDeletePathologyDetail, usePathologyDetails} from '../../../../_hooks/pathology-detail/use-pathology-details';
import {APIListPayload} from '../../../../_services/api/_helpers/api-list-types';
import PathologyDetailForm from './PathologyDetailForm';
import {ReactComponent as TrashIcon} from '../../../../_icons/trash.svg';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import * as React from 'react';
import {CompactTable} from '@table-library/react-table-library/compact';
import {useTranslation} from 'react-i18next';
import {PathologyDetailCategoryCaption} from '../_commons/captions';
import {APIPathologyDetail} from '../../../../_services/api/_helpers/api-types';
import Modal from '../../../_ui/modal';
import Button from '../../../_ui/button';

const DeleteCell = ({onDelete}) => {
  return (
    <div>
      <TrashIcon
        color={"#FF453A"}
        onClick={() => onDelete?.()}
        height={"100%"}
      />
    </div>
  );
};

const PathologyDetailsTable = ({payload, readonly = false, onDelete} : {payload?: APIListPayload, readonly?: boolean, onDelete?: any}) => {
  const [selectedPathologyDetail, setSelectedPathologyDetail] = useState<Partial<APIPathologyDetail> | null>(null);
  const {getPathologyDetails, pathology_details} =  usePathologyDetails(payload);
  const {t} = useTranslation();
  const {deletePathologyDetail} = useDeletePathologyDetail();

  const data = {nodes: pathology_details?.list || []};

  const handleCellClick = setSelectedPathologyDetail;

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 70px minmax(120px, 225px) minmax(200px, 1fr) 120px 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = [
    {
      label: 'ID',
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Catégorie',
      renderCell: (item) => (
        <PathologyDetailCategoryCaption
          className={item.category}
        >
          {t(`enums.pathology_detail.category.${item.category}`)}
        </PathologyDetailCategoryCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Label',
      renderCell: (item) => <span title={item.text}>{item.text}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Date de création',
      renderCell: (item) => new Date(item.created_at).toLocaleDateString('fr-fr'),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: '',
      renderCell: (item) => (
        <DeleteCell
          onDelete={async () => {
            if (item.id && window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
              if (onDelete) {
                onDelete(item)
              } else {
                await deletePathologyDetail(item.id);
              }
              setTimeout(() => getPathologyDetails(), 200)
            }
          }}
        />
      ),
    },
  ];

  return (
    <>
      {!readonly && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => setSelectedPathologyDetail({})}
          >
            Ajouter
          </Button>
        </div>
      )}
      {selectedPathologyDetail && (
        // @ts-ignore
        <Modal
          withPadding
          isOpen={!!selectedPathologyDetail}
          onRequestClose={() => setSelectedPathologyDetail(null)}
          overlay={true}
          withWrapper={true}
        >
          <h2>{selectedPathologyDetail.id ? `Modification du pathology helper #${selectedPathologyDetail.id}` : 'Création d\'un pathology helper'}</h2>
          <PathologyDetailForm
            pathologyDetail={selectedPathologyDetail}
            onChange={() => {
              setTimeout(getPathologyDetails, 200);
              setSelectedPathologyDetail(null);
            }}
          />
        </Modal>
      )}
      <CompactTable
        columns={COLUMNS}
        data={data}
        theme={theme}
        layout={{
          custom: true,
          horizontalScroll: true,
          fixedHeader: true,
        }}
      />
    </>
  );
};

const PathologyDetailsPage = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const {t} = useTranslation();

  const items = Object.keys(t('enums.pathology_detail.category')).map((category) => (
    {
      key: category,
      element: <span>{t(`enums.pathology_detail.category.${category}`)}</span>,
      onClick: () => setActiveTab(category),
    }
  ));

  const categoryKey = activeTab || items[0]?.key;

  return (
    <PageContainer
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <TabbedNavigation
        items={items}
        defaultActive={items.findIndex((item) => item.key === categoryKey)}
      />
      {categoryKey && (
        <PathologyDetailsTable
          payload={{filters: {category: [categoryKey]}}}
        />
      )}
    </PageContainer>
  );
};

export default PathologyDetailsPage;

export {
  PathologyDetailsTable,
};