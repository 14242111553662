const {
  // @ts-ignore
  REACT_APP_API_URL,
  // @ts-ignore
  REACT_APP_BUGSNAG_API_KEY,
  // @ts-ignore
  REACT_APP_ENV,
  NODE_ENV,
} = (process.env || {});

const REACT_APP_ENVIRONMENT = REACT_APP_ENV || NODE_ENV;

const {
  name: REACT_APP_NAME,
  version: REACT_APP_VERSION,
} = require("../../package.json");

export {
  REACT_APP_NAME,
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_API_URL,
  REACT_APP_BUGSNAG_API_KEY,
};
