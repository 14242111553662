import BottomBar from '../bottom-bar';
import {ReactComponent as HomeIcon} from '../../../_icons/home.svg';
import {ReactComponent as DrawIcon} from '../../../_icons/draw.svg';
import {ReactComponent as HistoryIcon} from '../../../_icons/history.svg';
import {ReactComponent as UserIcon} from '../../../_icons/user.svg';
import {ROUTES} from '../../../router/routes';

const items = [
  {
    Icon: HomeIcon,
    external: false,
    link: ROUTES.DASHBOARD(),
    target: undefined,
  },
  {
    Icon: DrawIcon,
    external: false,
    link: ROUTES.DIAGNOSTICS('new'),
    target: undefined,
  },
  {
    Icon: HistoryIcon,
    external: false,
    link: ROUTES.DIAGNOSTICS(),
    target: undefined,
  },
  {
    Icon: UserIcon,
    external: false,
    link: ROUTES.PROFILES('me'),
    target: undefined,
  },
];
const DashboardLayout = ({children}) => {
  return (
    <div
      style={{
        flex: 1,
        minHeight: '100svh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          flex: 1,
          maxHeight: 'calc(100svh - 76px)',
          overflow: 'scroll',
        }}
      >
        {children}
      </div>
      <BottomBar
        style={{alignSelf: 'flex-end', width: '100svw'}}
        items={items}
      />
    </div>
  );
};

export default DashboardLayout;