import { useEffect, useState } from "react";
import { useCheckToken } from "../../_hooks/auth";
import { Provider } from "../../_services/utils";
import { AuthenticationContext } from "./context";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {UserAPIService} from '../../_services/api';
import {APIUser} from '../../_services/api/auth/types';
import {logout} from '../../_services/api/auth';
import {ROUTES} from '../../router/routes';

function AuthProvider({ children }: Provider) {
  const { isTokenStored } = useCheckToken();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isTokenStored);
  const [user, setUser] = useState<APIUser | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, _setSearchParams] = useSearchParams();
  const isDashboardPage = /^\/dashboard/.test(location.pathname);

  const refetchUser = async () => {
    try {
      const userData = await UserAPIService.getUser();
      setUser(userData);
    } catch (e) {
      if (e.response.status === 401) {
        await logout(false);
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        await refetchUser();
      })();
      navigate(searchParams.get('redirect_url') || ROUTES.DASHBOARD());
    } else {
      setUser(null);
      if (isDashboardPage) {
        const params = new URLSearchParams();
        params.append('redirect_url', searchParams.get('redirect_url') || location.pathname + location.search );
        navigate(`${ROUTES.LOGIN()}?${params.toString()}`);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsLoggedIn(isTokenStored);
  }, [isTokenStored]);

  //TODO type this object with context values
  const value = {
    isLoggedIn,
    //TODO Do not expose internal state function
    setIsLoggedIn,
    user,
    setUser,
    refetchUser,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {(isLoggedIn || (!isDashboardPage)) && children}
    </AuthenticationContext.Provider>
  );
}

export default AuthProvider;
