import {useMutation, useQuery, useQueryClient} from "react-query";
import {PathologyAPIService} from "../../_services/api";
import {pathologiesKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIPathology, APIRequestPathologyMatches} from '../../_services/api/_helpers/api-types';

export function usePathologies(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [pathologiesKeys.pathologies, payload],
    (formData : object) => PathologyAPIService.getPathologies(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getPathologies: refetch,
    pathologies: data,
    isLoading,
    isError,
    error,
  };
}

export function usePathology({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    pathologiesKeys.pathologies,
    (formData : object) => PathologyAPIService.getPathology(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          pathologiesKeys.pathologies,
          (pathologies: APIListResponse<APIPathology>) => (
            {
              ...pathologies,
              //@ts-ignore
              list: [...(pathologies?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      enabled: id && id !== 'new',
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getPathology: refetch,
    pathology: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreatePathology() {
  const queryClient = useQueryClient();
  const { isLoading, isError, error, mutateAsync } = useMutation(
    pathologiesKeys.pathologies,
    (formData : object) => PathologyAPIService.createPathology(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologiesKeys.pathologies,
          (pathologies: APIListResponse<APIPathology>) => (
            {
              ...(pathologies || {}),
              list: [...(pathologies?.list || []), data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createPathology: mutateAsync,
    isLoading,
    isError,
    error,
  };
}

export function useUpdatePathology() {
  const queryClient = useQueryClient();
  const { isLoading, isError, error, mutateAsync } = useMutation(
    pathologiesKeys.pathologies,
    (formData : object ) => PathologyAPIService.updatePathology(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologiesKeys.pathologies,
          (pathologies: APIListResponse<APIPathology>) => (
            {
              ...(pathologies || {}),
              //@ts-ignore
              list: [...(pathologies?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updatePathology: mutateAsync,
    isLoading,
    isError,
    error,
  };
}

export function useDeletePathology() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    pathologiesKeys.pathologies,
    (id: number) => PathologyAPIService.deletePathology(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          pathologiesKeys.pathologies,
          (pathologies: APIListResponse<APIPathology>) => ({
            ...(pathologies || {}),
            list: [...(pathologies?.list || []).filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deletePathology: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}

export function useMatchesPathologies(payload: APIRequestPathologyMatches) {
  const { refetch, isLoading, isError, error, data, } = useQuery(
    [pathologiesKeys.pathologies, payload],
    (formData : object) => PathologyAPIService.getMatchesPathologies(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getPathologies: refetch,
    pathologies: data,
    isLoading,
    isError,
    error,
  };
}

export function useAddPathologyDetailToPathology() {
  const { mutate, isLoading, isError, error } = useMutation(
    pathologiesKeys.pathologies,
    (formData : object ) => PathologyAPIService.addPathologyDetailToPathology(formData),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    addPathologyDetailToPathology: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useRemovePathologyDetailToPathology() {
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    pathologiesKeys.pathologies,
    ({id, pathologyDetailId} : {id: number, pathologyDetailId: number}) => PathologyAPIService.removePathologyDetailToPathology(id, pathologyDetailId),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    removePathologyDetailToPathology: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}