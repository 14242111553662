import {useMutation, useQuery, useQueryClient} from "react-query";
import {DiagnosticAPIService} from "../../_services/api";
import {diagnosticsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIDiagnostic} from '../../_services/api/_helpers/api-types';

export function useDiagnostics(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [diagnosticsKeys.diagnostics, payload],
    (formData : object) => DiagnosticAPIService.getDiagnostics(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getDiagnostics: refetch,
    diagnostics: data,
    isLoading,
    isError,
    error,
  };
}

export function useDiagnostic({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    diagnosticsKeys.diagnostics,
    (formData : object) => DiagnosticAPIService.getDiagnostic(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          diagnosticsKeys.diagnostics,
          (diagnostics: APIListResponse<APIDiagnostic>) => (
            {
              ...(diagnostics || {}),
              //@ts-ignore
              list: [...(diagnostics?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
      enabled: id && id !== 'new',
    }
  );
  return {
    getDiagnostic: refetch,
    diagnostic: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateDiagnostic() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    diagnosticsKeys.diagnostics,
    (formData : object) => DiagnosticAPIService.createDiagnostic(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          diagnosticsKeys.diagnostics,
          (diagnostics: APIListResponse<APIDiagnostic>) => (
            {
              ...diagnostics,
              list: [...diagnostics?.list, data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createDiagnostic: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdateDiagnostic() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    diagnosticsKeys.diagnostics,
    (formData : object ) => DiagnosticAPIService.updateDiagnostic(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          diagnosticsKeys.diagnostics,
          (diagnostics: APIListResponse<APIDiagnostic>) => (
            {
              ...diagnostics,
              //@ts-ignore
              list: [...(diagnostics?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updateDiagnostic: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteDiagnostic() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    diagnosticsKeys.diagnostics,
    (id: number) => DiagnosticAPIService.deleteDiagnostic(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          diagnosticsKeys.diagnostics,
          (diagnostics: APIListResponse<APIDiagnostic>) => ({
            ...diagnostics,
            list: [...diagnostics?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteDiagnostic: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}