import {useUsers} from '../../../../_hooks/user/use-users';
import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../../_ui/page';
import {ROUTES} from '../../../../router/routes';
import {useNavigate} from 'react-router-dom';
import {STRIPE_ROUTES} from '../../../../_services/stripe/routes';
import {UserRoleCaption, UserPlanCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';

const COLUMNS = (handleCellClick, t) => [
  {
    label: 'ID',
    renderCell: (item) => item.id.toString(),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Role',
    renderCell: (item) => (
      <UserRoleCaption
        className={item.role}
      >
        {t(`enums.user.role.${item.role}`)}
      </UserRoleCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'RPPS',
    renderCell: (item) => item.professional_number,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Prénom',
    renderCell: (item) => item.first_name,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Nom',
    renderCell: (item) => item.last_name,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Crédits',
    renderCell: (item) => item.balance,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Abonnement',
    renderCell: (item) => (
      <UserPlanCaption
        className={item.plan}
      >
        {t(`enums.user.plan.${item.plan}`)}
      </UserPlanCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Stripe',
    renderCell: (item) => (
      <a
        target="_blank"
        href={STRIPE_ROUTES.CUSTOMERS(item.stripe_customer_id)}
      >
        {item.stripe_customer_id}
      </a>
    ),
  },
  {
    label: 'Date Inscription',
    renderCell: (item) =>
      new Date(item.created_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Dernière connexion',
    renderCell: (item) =>
      item.current_sign_in_at && new Date(item.current_sign_in_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
];

const UsersTable = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {users, isLoading} = useUsers({
    page_size: 1000,
    sorting: [
      {
        name: 'id',
        direction: 'asc',
      },
    ],
  });

  const handleCellClick = (data) => {
    navigate(ROUTES.USERS(data.id));
  };

  const data = {nodes: users?.list || []};

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px 120px 140px repeat(2, minmax(120px, 180px)) 100px minmax(140px, 180px) 180px repeat(2, minmax(120px, 200px));
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  return (
    <div style={{textAlign: 'left'}}>
      <CompactTable
        columns={COLUMNS(handleCellClick, t)}
        data={data}
        theme={theme}
        layout={{
          custom: true,
          horizontalScroll: true,
          fixedHeader: true,
        }}
      />
    </div>
  );
};

const UsersPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
    }}
  >
    <h1>Utilisateurs</h1>
    <UsersTable/>
  </PageContainer>
);

export default UsersPage;