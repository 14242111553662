import styled from 'styled-components';
import Button from '../../../_ui/button';
import {useAuthContext} from '../../../../_contexts/auth';
import {useEffect, useState} from 'react';
import {useDeleteUser} from '../../../../_hooks/user/use-users';
import {UserAPIService} from '../../../../_services/api';
import {useNavigate} from 'react-router-dom';
import {Form, TextField} from '../../../_ui/form';
import {useFormik} from 'formik';
import {userFormValidationSchema} from './validation-schema';
import {APIUser} from '../../../../_services/api/auth/types';
import Caption from '../../../_ui/caption';
import {ROUTES} from '../../../../router/routes';
import * as React from 'react';
import {logout} from '../../../../_services/api/auth';
import MascotModal from '../../../_ui/mascot-modal';
import Avatar from '../../../_ui/avatar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`;

const UserForm = (
  {initialValues, onSuccess}:
    {initialValues: APIUser, onSuccess: Function}
) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      ...initialValues,
      password: '',
    },
    onSubmit: (formValues) => {
      const {password, ...userInfos} = formValues;
      if (!!password) {
        // @ts-ignore
        userInfos.password = password;
      }
      UserAPIService.updateUser(userInfos).then((user) => {
        setSubmitting(false);
        resetForm();
        onSuccess?.();
      });
    },
    validationSchema: userFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form style={{padding: '12px 20px 12px 20px'}}>
      <TextField
        name="first_name"
        required
        label={"Prénom"}
        placeholder={"Prénom"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.first_name}
        hasError={touched.first_name && !!errors.first_name}
        errorMessage={errors.first_name}
        smallVerticalPadding
      />
      <TextField
        name="last_name"
        required
        label={"Nom"}
        placeholder={"Nom"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.last_name}
        hasError={touched.last_name && !!errors.last_name}
        errorMessage={errors.last_name}
        smallVerticalPadding
      />
      <TextField
        type="email"
        name="email"
        required
        label={"Email"}
        placeholder={"Email"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        hasError={touched.email && !!errors.email}
        errorMessage={errors.email}
        smallVerticalPadding
      />
      <TextField
        type="password"
        name="password"
        label={"Mot de passe"}
        placeholder={"********"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        hasError={touched.password && !!errors.password}
        errorMessage={errors.password}
        smallVerticalPadding
      />
      <TextField
        type="date"
        name="birth_date"
        required
        label={"Date de naissance"}
        placeholder={"13/01/1989"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.birth_date || ''}
        hasError={touched.birth_date && !!errors.birth_date}
        errorMessage={errors.birth_date}
        smallVerticalPadding
      />
      <Button
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit}
      >
        Sauvegarder
      </Button>
    </Form>
  );
};

const UserPage = () => {
  const {user, setUser} = useAuthContext();
  const [openModal, setOpenModal] = useState<string | null>(null)
  const {deleteUser, isSuccess: deleteUserIsSuccess} = useDeleteUser();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const userData = await UserAPIService.getUser();
      if (JSON.stringify(userData) !== JSON.stringify(user)){
        setUser(userData);
      }
    })();
  }, []);

  useEffect(() => {
    if (deleteUserIsSuccess) {
      (async () => await logout(false))();
    }
  }, [deleteUserIsSuccess]);

  const deleteMyAccount = async () => {
    await deleteUser(user.id);
  };

  return (
    <Container
      style={{maxHeight: 'calc(100svh - 76px)'}}
    >
      <h1 style={{flex: 1, margin: '20px', textAlign: 'center'}}>Mes informations</h1>
      <Avatar/>
      <div
        style={{
          padding: '0px 20px 0px 20px',
          display: 'flex',
          gap: 12,
          justifyContent: 'center',
          fontWeight: 'bold',
        }}
      >
        Mon code de parrainage
        <Caption
          copy
          copyContent={`${window.location.origin}${ROUTES.SIGNUP()}?sponsor_code=${user.sponsorship_code}`}
        >
          {user.sponsorship_code}
        </Caption>
      </div>
      <Container
        style={{
          flex: 1,
          overflowY: 'scroll',
        }}
      >
        <UserForm
          initialValues={user}
          onSuccess={() => navigate(-1)}
        />
        <span
          onClick={() => setOpenModal('deleteAccountModal')}
        >
          Supprimer mon compte
        </span>
      </Container>
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={deleteMyAccount}
        translationKey={`user.${openModal}`}
      />
    </Container>
  );
};

export default UserPage;