import styled from 'styled-components';
import Caption from '../../../_ui/caption';

const PathologyStatusCaption = styled(Caption)`
  &.draft {
    background-color: #a14cff22;
    border-color: #a14cff;
  }
  &.active {
    // default
  }
`;

const PathologyCategoryCaption = styled(Caption)`
  &.neurology {
    background-color: #B0D8EB22;
    border-color: #B0D8EB;
  }
  &.musculotendinous {
    background-color: #F7BBA822;
    border-color: #F7BBA8;
  }
  &.osteoarticular {
    background-color: #F7EB8A22;
    border-color: #F7EB8A;
  }
  &.other {
    background-color: #B2EBDA22;
    border-color: #B2EBDA;
  }
`;

const PathologyRecurrentCaption = styled(Caption)`
  &.false {
    background-color: #ff005d22;
    border-color: #ff005d;
    background-color: #ebebeb22;
    border-color: #ebebeb;
  }
  &.true {
    // default
  }
`;

const PathologyDetailCategoryCaption = styled(Caption)`
  &.event {
    background-color: #2b9dfe22;
    border-color: #2b9dfe;
  }
  &.symptom_reproduction {
    background-color: #ffc73622;
    border-color: #ffc736;
  }
  &.positive_test {
    background-color: #ffa5c522;
    border-color: #ffa5c5;
  }
  &.relevant_observation {
    background-color: #08224c22;
    border-color: #08224c;
  }
`;

const UserRoleCaption = styled(Caption)`
  &.registered {
    background-color: #ff005d22;
    border-color: #ff005d;
  }
  &.admin {
    background-color: #ffc73622;
    border-color: #ffc736;
  }
  &.professional {
    background-color: #2b9dfe22;
    border-color: #2b9dfe;
  }
  &.student {
    background-color: #08224c22;
    border-color: #08224c;
  }
`;

const UserPlanCaption = styled(Caption)`
  &.trial {
    background-color: #a14cff22;
    border-color: #a14cff;
  }
  &.premium {
    // default
  }
`;

const UserRequestAcceptedCaption = styled(Caption)`
  background-color: #ffc73622;
  border-color: #ffc736;
  &.false {
    background-color: #ff005d22;
    border-color: #ff005d;
  }
  &.true {
    background-color: #E1FCA6;
    border-color: #ADE82F;
  }
  &.null {
    background-color: #ffc73622;
    border-color: #ffc736;
  }
`;

const BalanceTransactionCategoryCaption = styled(Caption)`
  &.regular {
    background-color: #ebebeb22;
    border-color: #ebebeb;
  }
  &.referral {
    background-color: #2b9dfe22;
    border-color: #2b9dfe;
  }
`;

const BalanceTransactionUnitCaption = styled(Caption)`
  &.coin {
    background-color: #ffc73622;
    border-color: #ffc736;
  }
  &.month {
    background-color: #ffa5c522;
    border-color: #ffa5c5;
  }
`;

export {
  PathologyStatusCaption,
  PathologyCategoryCaption,
  PathologyRecurrentCaption,
  PathologyDetailCategoryCaption,
  UserRoleCaption,
  UserPlanCaption,
  UserRequestAcceptedCaption,
  BalanceTransactionCategoryCaption,
  BalanceTransactionUnitCaption,
};