import {usePathologies} from '../../../../_hooks/pathology/use-pathologies';
import {useState} from 'react';
import {Autocomplete} from '../../../_ui/form';
import * as React from 'react';
import {PathologyCategoryCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';
import {APIPathology} from '../../../../_services/api/_helpers/api-types';

type PathologySearchProps = {
  onChange: (pathology: APIPathology) => any;
};

const PathologySearch = ({onChange} : PathologySearchProps) => {
  const {t} = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const {pathologies} = usePathologies({
    filters: {
      search: [searchText]
    },
    page_size: 20,
  });

  return (
    <Autocomplete
      name="search"
      label={"Recherche"}
      placeholder={"Nom de Pathologie, ..."}
      onTextChange={setSearchText}
      text={searchText}
      onChange={onChange}
      items={pathologies?.list}
      Item={({item: pathology}) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '25px 100px 1fr',
            gap: 8,
            alignItems: 'center',
          }}
        >
          {pathology.id}
          <PathologyCategoryCaption
            className={pathology.category}
          >
            {t(`enums.pathology.category.${pathology.category}`)}
          </PathologyCategoryCaption>
          {pathology.text}
        </div>
      )}
    />
  );
};

export default PathologySearch;