import {APIQuote} from '../../../../_services/api/_helpers/api-types';
import {useFormik} from 'formik';
import {Form, TextField} from '../../../_ui/form';
import Button from '../../../_ui/button';
import * as React from 'react';
import {useCreateQuote, useUpdateQuote} from '../../../../_hooks/quote/use-quotes';
import {quoteFormValidationSchema} from './validation-schema';

const QuoteForm = (
  {initialValues = {}, onSuccess} :
    {initialValues?: Partial<APIQuote>, onSuccess?: any}
) => {
  const {createQuote, isLoading: isCreateLoading} = useCreateQuote();
  const {updateQuote, isLoading: isUpdateLoading} = useUpdateQuote();
  const isLoading = isCreateLoading || isUpdateLoading;
  const {errors, handleChange, handleSubmit, values, isValid, isSubmitting, touched, handleBlur, setSubmitting, resetForm} = useFormik({
    initialValues: {
      text: '',
      ...initialValues,
    },
    onSubmit: async (formValues) => {
      if (formValues.id) {
        await updateQuote(formValues);
      } else {
        await createQuote(formValues);
      }
      onSuccess?.();
      setSubmitting(false);
      resetForm();
    },
    validationSchema: quoteFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form>
      <TextField
        type="text"
        name="text"
        required
        placeholder={"Votre citation"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.text}
        hasError={touched.text && !!errors.text}
        errorMessage={errors.text}
      />
      <Button
        type="submit"
        disabled={!isValid || (isLoading || isSubmitting)}
        onClick={handleSubmit}
      >
        {values.id ? 'Mettre à jour' : 'Créer'}
      </Button>
    </Form>
  )
};

export default QuoteForm;