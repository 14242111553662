import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../../_ui/page';
import {useDeleteQuote, useQuotes} from '../../../../_hooks/quote/use-quotes';
import Button from '../../../_ui/button';
import Modal from '../../../_ui/modal';
import {APIQuote} from '../../../../_services/api/_helpers/api-types';
import {useState} from 'react';
import QuoteForm from './QuoteForm';
import {ReactComponent as TrashIcon} from '../../../../_icons/trash.svg'

const DeleteCell = ({id, onDelete}) => {
  const {deleteQuote} = useDeleteQuote();

  return (
    <TrashIcon
      color={"#FF453A"}
      onClick={async () => {
        if (id && window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
          await deleteQuote(id);
          onDelete?.();
        }
      }}
      height={"100%"}
    />
  );
};

const COLUMNS = (handleCellClick, onDelete) => [
  {
    label: 'ID',
    renderCell: (item) => item.id.toString(),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Contenu',
    renderCell: (item) => item.text,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Créée le',
    renderCell: (item) =>
      new Date(item.created_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Modifiée le',
    renderCell: (item) =>
      new Date(item.updated_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: '',
    renderCell: (item) => (
      <DeleteCell id={item.id} onDelete={onDelete}/>
    ),
  },
];

const QuotesTable = () => {
  const [isModalOpen, setIsModalOpen] = useState<false | Partial<APIQuote>>(false);
  const {quotes, getQuotes, isLoading} = useQuotes({
    page_size: 1000,
    sorting: [
      {
        name: 'created_at',
        direction: 'desc',
      },
    ],
  });

  const handleCellClick = setIsModalOpen;

  const refreshQuotes = () => setTimeout(getQuotes, 200);

  const data = {nodes: quotes?.list || []};

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px 1fr minmax(120px, 180px) minmax(120px, 180px) 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => setIsModalOpen({})}
        >
          Ajouter
        </Button>
      </div>
      <div style={{textAlign: 'left'}}>
        <CompactTable
          columns={COLUMNS(handleCellClick, refreshQuotes)}
          data={data}
          theme={theme}
          layout={{
            custom: true,
            horizontalScroll: true,
            fixedHeader: true,
          }}
        />
      </div>
      {isModalOpen && (
        // @ts-ignore
        <Modal
          withPadding
          isOpen={!!isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          overlay={true}
          withWrapper={true}
        >
          <h2>{isModalOpen.id ? 'Modifier une citation' : 'Création d\'une citation'}</h2>
          <QuoteForm
            initialValues={isModalOpen}
            onSuccess={() => {
              refreshQuotes();
              setIsModalOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};

const QuotesPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
    }}
  >
    <h1>Citations</h1>
    <QuotesTable/>
  </PageContainer>
);

export default QuotesPage;