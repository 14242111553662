import {useEffect, useRef, useState} from 'react';
import {CheckBox} from '../../../_ui/form';
import {
  APIPathology,
  APIPathologyCategory,
  APIRequestPathologyMatch
} from '../../../../_services/api/_helpers/api-types';
import styled from 'styled-components';
import BottomBar from '../../../_ui/bottom-bar';
import {ROUTES} from '../../../../router/routes';
import {ReactComponent as NemoButtonIcon} from '../../../../_icons/nemo_button.svg';
import {ReactComponent as NeuroIcon} from '../../../../_icons/pathology/neuro.svg';
import {ReactComponent as MuscuIcon} from '../../../../_icons/pathology/muscu.svg';
import {ReactComponent as OsteoIcon} from '../../../../_icons/pathology/osteo.svg';
import {ReactComponent as OtherIcon} from '../../../../_icons/pathology/other.svg';
import {ReactComponent as PenIcon} from '../../../../_icons/pen.svg';
import {ReactComponent as HelpIcon} from '../../../../_icons/help.svg';
import {ReactComponent as EventIcon} from '../../../../_icons/pathology_detail/event.svg';
import {ReactComponent as RelevantObservationIcon} from '../../../../_icons/pathology_detail/relevant_observation.svg';
import {ReactComponent as PositiveTestIcon} from '../../../../_icons/pathology_detail/positive_test.svg';
import {ReactComponent as SymptomReproductionIcon} from '../../../../_icons/pathology_detail/symptom_reproduction.svg';
import {ReactComponent as NavigateBeforeIcon} from '../../../../_icons/navigate_before.svg';
import {ReactComponent as HorizontalArrowIcon} from '../../../../_icons/horizontal-arrow.svg';
import {ReactComponent as ForbiddenIcon} from '../../../../_icons/forbidden.svg';
import {useNavigate, useParams} from 'react-router-dom';
import {useDiagnostic} from '../../../../_hooks/diagnostic/use-diagnostics';
import groupBy from '../../../../_services/utils/group-by';
import {attachPathologyDetailsToDiagnostic} from '../../../../_services/api/diagnostic';
import MascotModal from '../../../_ui/mascot-modal';
import Modal from '../../../_ui/modal';
import QuoteForm from '../../admin/quotes/QuoteForm';
import * as React from 'react';

const MIN_MATCH_PERCENT = 3;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100svh - 86px);
`;

const HeaderTitle = styled.h1`
  color: var(--Text-primary, #1D1D1B);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  /* Mobile/Headline */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const BackButton = styled.span`
  color: var(--Text-neutral-5, #8E8E8E);
  font-feature-settings: 'liga' off, 'clig' off;

  /* Mobile/Body */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const pathologyCategoriesProps = [
  {
    label: 'neurology',
    Icon: NeuroIcon,
    title: 'Neurologie',
    menuTitle: 'Neuro',
    backgroundColorPrimary: '#F0FAFF',
    backgroundColorSecondary: '#D7F0FC',
    backgroundColorButton: '#B0D8EB',
  },
  {
    label: 'musculotendinous',
    Icon: MuscuIcon,
    title: 'Musculo-tendineux',
    menuTitle: 'Musc',
    backgroundColorPrimary: '#FFF7F5',
    backgroundColorSecondary: '#FFE7DE',
    backgroundColorButton: '#FFB299',
  },
  {
    label: 'osteoarticular',
    Icon: OsteoIcon,
    title: 'Ostéo-articulaire',
    menuTitle: 'Os-Art',
    backgroundColorPrimary: '#FFFCE5',
    backgroundColorSecondary: '#FCF4C0',
    backgroundColorButton: '#F7E76F',
  },
  {
    label: 'other',
    Icon: OtherIcon,
    title: 'Autre',
    menuTitle: 'Autre',
    backgroundColorPrimary: '#F3FCF7',
    backgroundColorSecondary: '#DAF7EE',
    backgroundColorButton: '#A2EBC5',
  },
];

const pathologyDetailCategoriesProps = [
  {
    label: 'event',
    Icon: EventIcon,
    title: 'Antécédents',
  },
  {
    label: 'symptom_reproduction',
    Icon: SymptomReproductionIcon,
    title: 'Reproduction des symptomes',
  },
  {
    label: 'relevant_observation',
    Icon: RelevantObservationIcon,
    title: 'Observations pertinentes',
  },
  {
    label: 'positive_test',
    Icon: PositiveTestIcon,
    title: 'Tests positifs',
  },
];

const Header = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<string | null>(null);

  const handleContinueModalActions = {
    helpModal: () => setOpenModal(null),
    guardianModal: () => navigate(ROUTES.DASHBOARD()),
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <HeaderTitle>Éléments Clinique</HeaderTitle>
        <HelpIcon onClick={() => setOpenModal('helpModal')}/>
      </div>
      <PenIcon
        style={{
          position: 'absolute',
          right: 20,
        }}
      />
      <BackButton
        style={{
          position: 'absolute',
          left: 20,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setOpenModal('guardianModal')}
      >
        <NavigateBeforeIcon/>
        {/*Quitter*/}
      </BackButton>
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={handleContinueModalActions[openModal]}
        translationKey={`clinicElements.${openModal}`}
      />
    </div>
  );
};

const PrimaryContainer = styled.div<{$color: string}>`
  display: flex;
  padding: 24px 16px;
  min-height: 100%;

  flex-direction: column;
  align-items: center;
  gap: 24px;

  flex-shrink: 0;
  border-radius: 40px 40px 0px 0px;
  box-sizing: border-box;
  background: ${props => props.$color};
`;

const SecondaryContainer = styled.div<{$color: string}>`
  display: flex;
  padding: 12px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--4, 16px);
  border-radius: 20px;
  box-sizing: border-box;
  background: ${props => props.$color};
`;

const SecondaryTitle = styled.h2`
  margin: 0;
  color: var(--Text-primary, #1D1D1B);
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/Title3 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.2px;
`;

const Counter = styled.div`
  display: flex;
  //width: 64px;
  height: 64px;
  padding: 16px 32px;

  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: var(--Background-Reverse, #1D1D1B);

  box-sizing: border-box;
  font-size: 17px;
`;

const PathologyDetailOption = styled.div<{$selected: boolean; $selectedBorderColor: string;}>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 8px;
  border: 2px solid #FFFFFF00;
  
  ${props => props.$selected && `
    border-color: ${props.$selectedBorderColor};
  `}
`;

const Accordion = ({currentPathologyCategoryProps, pathologyDetailCategoryProps, items, onChange, selectedIds, pathologyDetailsFiltered}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SecondaryContainer
      $color={currentPathologyCategoryProps.backgroundColorSecondary}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10,
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            gap: 10,
          }}
        >
          <pathologyDetailCategoryProps.Icon
            style={{
              backgroundColor: currentPathologyCategoryProps.backgroundColorPrimary,
              borderRadius: 12,
              padding: 4,
            }}
          />
          <SecondaryTitle>{pathologyDetailCategoryProps.title}</SecondaryTitle>
        </div>
        {items.length > 0 && (
          <HorizontalArrowIcon
            onClick={() => setIsOpen(prev => !prev)}
            style={{display: 'flex', justifySelf: 'flex-end', transform: `rotate(${isOpen ? 0 : 180}deg)`}}
          />
        )}
        {items.length <= 0 && (
          <ForbiddenIcon
            style={{display: 'flex', justifySelf: 'flex-end'}}
          />
        )}
      </div>
      {isOpen && items.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '100%',
          }}
        >
          {
            items.map((item, index2) => (
              <PathologyDetailOption
                key={index2}
                $selected={pathologyDetailsFiltered.map(pd => pd.id).includes(item.id)}
                $selectedBorderColor={currentPathologyCategoryProps.backgroundColorButton}
                style={{
                  backgroundColor: currentPathologyCategoryProps.backgroundColorPrimary,
                }}
              >
                <CheckBox
                  key={pathologyDetailCategoryProps.label + '_' + index2}
                  name={item.id.toString()}
                  checked={selectedIds.includes(item.id)}
                  onChange={onChange}
                  style={{alignItems: 'flex-start'}}
                  label={(
                    <span
                      style={{
                        fontSize: 16,
                        lineHeight: '21px',
                        color: '#393937',
                        fontWeight: selectedIds.includes(item.id) ? 600 : 400,
                      }}
                    >
                      {item.text}
                    </span>
                  )}
                />
              </PathologyDetailOption>
            ))
          }
        </div>
      )}
    </SecondaryContainer>
  );
};

const OptionStyled = styled.div<{$selected?: boolean;}>`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-radius: var(--M, 16px);
  // border: 1px solid #EBEBE8;
  box-sizing: border-box;
  background-color: #F5F5F5;
  
  ${props => props.$selected && `
    background-color: #1D1D1D;
    color: #FCFCFC;
    // border: 2px solid #ADE82F;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  `}
`;

const PathologyFilterContainer = styled.div`
  position: fixed;
  top: 65px;
  right: 0;
  z-index: 1;
  display: flex;
  width: 245px;
  padding: 12px 16px 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--M, 16px) 0px 0px var(--M, 16px);
  border: 1px solid var(--Colors-Neutral-grey-80, #EDEDED);
  background: var(--Background-Primary, #FFF);
  box-sizing: border-box;

  /* Drop Shadow/l */
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  
  h2 {
    margin: 0;
  }
`;

const PathologyFilter = ({recurrentPathologies, notRecurrentPathologies, value, onChange, onClose}) => (
  <PathologyFilterContainer>
    {notRecurrentPathologies.length > 0 && (
      <>
        <h2 onClick={onClose}>Pathologies</h2>
        <BackButton
          style={{
            position: 'absolute',
            right: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={onClose}
        >
          <NavigateBeforeIcon style={{transform: 'rotateY(180deg)'}}/>
        </BackButton>
        <div
          style={{
            display: 'flex',
            maxHeight: 350,
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 16,
            boxSizing: 'border-box',
            overflowY: 'scroll',
          }}
        >
          {notRecurrentPathologies.map((pathology, index) => (
            <OptionStyled
              key={index}
              $selected={value.includes(pathology.id)}
              onClick={() => onChange(prev => prev.includes(pathology.id) ? [...prev.filter(v => v !== pathology.id)] : [...prev, pathology.id])}
            >
              {pathology.text}
            </OptionStyled>
          ))}
        </div>
      </>
    )}
    {recurrentPathologies.length > 0 && (
      <>
        <h2 onClick={onClose}>Pathologies Récurrentes</h2>
        {recurrentPathologies.map((pathology, index) => (
          <OptionStyled
            key={index}
            $selected={value.includes(pathology.id)}
            onClick={() => onChange(prev => prev.includes(pathology.id) ? [...prev.filter(v => v !== pathology.id)] : [...prev, pathology.id])}
          >
            {pathology.text}
          </OptionStyled>
        ))}
      </>
    )}
  </PathologyFilterContainer>
);

const DiagnosticPathologyDetailsPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [pathologyCategory, setPathologyCategory] = useState<APIPathologyCategory>('neurology');
  const [selectedPathologyDetails, setSelectedPathologyDetails] = useState([]);
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [pathologyFilters, setPathologyFilters] = useState<number[]>([]);

  const {diagnostic, isLoading} = useDiagnostic({id});

  const currentPathologyCategoryProps = pathologyCategoriesProps.find(cat => cat.label === pathologyCategory);

  const diagnosticVersion = diagnostic?.diagnostic_versions?.[0];

  useEffect(() => {
    if (diagnosticVersion?.id) {
      setSelectedPathologyDetails(diagnosticVersion?.pathology_details?.map(pd => pd.id) || []);
    }
  }, [diagnosticVersion]);

  if (isLoading) {
    return null;
  }

  const pathologiesGrouped = groupBy(
    (diagnosticVersion?.computed || []).map(p => p.pathology as APIPathology),
    'category'
  );
  const allPathologies = pathologiesGrouped?.[currentPathologyCategoryProps.label] || [];
  const recurrentPathologies = allPathologies.filter(p => p.recurrent);
  const notRecurrentPathologies = allPathologies.filter(p => !p.recurrent);
  const recurrentPathologiesCount = recurrentPathologies.length
  const notRecurrentPathologiesCount = notRecurrentPathologies.length

  const detailsGrouped = groupBy(
    allPathologies.filter(p => !p.recurrent || pathologyFilters.includes(p.id)).flatMap(p => p.pathology_details),
    'category'
  );

  Object.keys(detailsGrouped).map(key => {
    detailsGrouped[key] = detailsGrouped[key].filter((elem, index, self) => (index === self.findIndex(e => e.id === elem.id)));
  });

  const activeStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    borderRadius: 16,
  });

  const inactiveStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    borderRadius: 16,
  });

  const savePathologyDetails = async () => {
    const result = await attachPathologyDetailsToDiagnostic({
      id: diagnostic.id,
      diagnostic_version_id: diagnosticVersion.id,
      pathology_detail_ids: selectedPathologyDetails,
    });
    navigate(ROUTES.DIAGNOSTIC_RESULT(id));
  };

  const menuItems = pathologyCategoriesProps.map(pathologyCategoryProps => ({
    Icon: (props) => (
      <div
        style={{
          height: 54,
          width: 54,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <pathologyCategoryProps.Icon {...props}/>
        {pathologyCategoryProps.menuTitle}
      </div>
    ),
    external: false,
    link: `#${pathologyCategoryProps.label}`,
    target: undefined,
    onClick: (e) => {
      e.preventDefault();
      setPathologyCategory(pathologyCategoryProps.label as APIPathologyCategory)
    },
    iconSize: {width: 24, height: 24},
    style: ({isActive}) => (
      isActive && currentPathologyCategoryProps?.label === pathologyCategoryProps.label
        ? activeStyle(pathologyCategoryProps.backgroundColorButton)
        : inactiveStyle(
          selectedPathologyDetails.find((id) =>
              pathologiesGrouped[pathologyCategoryProps.label]?.flatMap(p => p.pathology_details.flatMap(pd => pd.id))?.includes(id)
            )
            ? pathologyCategoryProps.backgroundColorSecondary
            : '#00000000'
        )
    ),
  }));

  // @ts-ignore
  menuItems.splice(2, 0, {
    Icon: NemoButtonIcon,
    external: false,
    link: undefined,
    target: undefined,
    onClick: savePathologyDetails,
    // @ts-ignore
    style: {width: 58, height: 58},
    iconSize: {width: 58, height: 58},
  });

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedPathologyDetails(prev => (Array.from(new Set([...prev, +event.target.name]))));
    } else {
      setSelectedPathologyDetails(prev => ([...prev.filter(v => v !== +event.target.name)]));
    }
  }

  return (
    <div style={{position: 'relative', paddingBottom: 100}}>
      <div>
        <Container>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Header/>
            <PrimaryContainer
              $color={currentPathologyCategoryProps.backgroundColorPrimary}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  gap: 16,
                  padding: '3px 16px',
                }}
              >
                <currentPathologyCategoryProps.Icon width={48} height={48}/>
                <div style={{flex: 1, height: 0, border: '1px solid #1D1D1B'}}></div>
                <div>
                  <div style={{position: 'relative'}}>
                    {isFilterModalOpen && (
                      <PathologyFilter
                        recurrentPathologies={recurrentPathologies}
                        notRecurrentPathologies={notRecurrentPathologies}
                        value={pathologyFilters}
                        onChange={setPathologyFilters}
                        onClose={() => setIsFilterModalOpen(prev => !prev)}
                      />
                    )}
                  </div>
                  <Counter
                    onClick={() => allPathologies.length > 0 && setIsFilterModalOpen(prev => !prev)}
                    style={{color: '#FFF', position: 'relative', fontWeight: 600}}
                  >
                    {notRecurrentPathologiesCount || 0} Pathologies
                    {recurrentPathologiesCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: -10,
                          right: 10,
                          padding: 2,
                          border: '1px solid #1D1D1D',
                          borderRadius: '50%',
                          backgroundColor: currentPathologyCategoryProps.backgroundColorButton,
                          height: 28,
                          width: 28,
                          color: '#1D1D1D',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                          fontSize: 11,
                        }}
                      >
                        +{recurrentPathologiesCount}
                      </div>
                    )}
                  </Counter>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  gap: 10,
                }}
              >
                <h1>{currentPathologyCategoryProps.title}</h1>
                <HelpIcon onClick={() => setOpenModal(`${currentPathologyCategoryProps.label}HelpModal`)}/>
              </div>
              {
                pathologyDetailCategoriesProps.map((pathologyDetailCategoryProps, index) => (
                  <Accordion
                    key={currentPathologyCategoryProps.label + '_' + index}
                    pathologyDetailsFiltered={pathologiesGrouped[currentPathologyCategoryProps.label]?.filter(p => pathologyFilters.includes(p.id))?.flatMap(p => p.pathology_details || [])}
                    currentPathologyCategoryProps={currentPathologyCategoryProps}
                    pathologyDetailCategoryProps={pathologyDetailCategoryProps}
                    items={detailsGrouped[pathologyDetailCategoryProps.label] || []}
                    onChange={handleChangeCheckbox}
                    selectedIds={selectedPathologyDetails}
                  />
                ))
              }
            </PrimaryContainer>
          </div>
        </Container>
      </div>
      <BottomBar
        style={{position: 'fixed', bottom: -10, width: '100svw'}}
        items={menuItems}
      />
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={() => setOpenModal(null)}
        translationKey={`clinicElements.${openModal}`}
      />
      {/*{isFilterModalOpen && (*/}
      {/*  // @ts-ignore*/}
      {/*  <Modal*/}
      {/*    withPadding*/}
      {/*    isOpen={isFilterModalOpen}*/}
      {/*    onRequestClose={() => setIsFilterModalOpen(false)}*/}
      {/*    overlay={true}*/}
      {/*    withWrapper={true}*/}
      {/*  >*/}
      {/*    {notRecurrentPathologiesCount > 0 && (*/}
      {/*      <>*/}
      {/*        <h2>Pathologies</h2>*/}
      {/*        {notRecurrentPathologies.map((pathology, index) => (*/}
      {/*          <OptionStyled*/}
      {/*            key={index}*/}
      {/*            $selected={pathologyFilter === pathology.id}*/}
      {/*            onClick={() => setPathologyFilter(prev => prev === pathology.id ? null : pathology.id)}*/}
      {/*          >*/}
      {/*            {pathology.text}*/}
      {/*          </OptionStyled>*/}
      {/*        ))}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*    {recurrentPathologiesCount > 0 && (*/}
      {/*      <>*/}
      {/*        <h2>Pathologies Récurrentes</h2>*/}
      {/*        {recurrentPathologies.map((pathology, index) => (*/}
      {/*          <OptionStyled*/}
      {/*            key={index}*/}
      {/*            $selected={pathologyFilter === pathology.id}*/}
      {/*            onClick={() => setPathologyFilter(prev => prev === pathology.id ? null : pathology.id)}*/}
      {/*          >*/}
      {/*            {pathology.text}*/}
      {/*          </OptionStyled>*/}
      {/*        ))}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </div>
  );
};

export default DiagnosticPathologyDetailsPage;