import styled from 'styled-components';
import {InputHTMLAttributes, ReactNode} from 'react';

const ContainerStyled = styled.div<{}>`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputContainerStyled = styled.div<{ $smallVerticalPadding?: boolean; }>`
  width: 100%;
  padding: ${(props) => props.$smallVerticalPadding ? '16px' : '8px'} 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const ErrorMessageContainerStyled = styled.div<{}>`
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.2px;

  span {
    color: #FF3B30;
  }
`;

const LabelStyled = styled.label<{}>`
  width: 100%;
  color: var(--Text-primary, #1D1D1B);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
  align-self: center;
`;

const InputStyled = styled.input<{ $hasError?: boolean; }>`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  width: 22px;
  height: 22px;
  
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.2px;

  color: ${(props) => props.$hasError ? '#FF3B30' : '#636360'};
`;

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  errorMessage?: string;
  hasError?: boolean;
}

const CheckBox = (
  {
    errorMessage,
    hasError = false,
    label,
    name,
    style,
    ...rest
  } : TextFieldProps
) => {
  return (
    <ContainerStyled>
      <InputContainerStyled style={style}>
        <InputStyled
          id={name}
          name={name}
          type="checkbox"
          {...rest}
          $hasError={hasError}
        />
        {label && (
          <LabelStyled htmlFor={name}>
            {label}
          </LabelStyled>
        )}
      </InputContainerStyled>
      {hasError && errorMessage && (
        <ErrorMessageContainerStyled>
          <span>{errorMessage}</span>
        </ErrorMessageContainerStyled>
      )}
    </ContainerStyled>
  );
};

export default CheckBox;