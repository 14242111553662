import {useAuthContext} from '../../../../_contexts/auth';
import Button from '../../../_ui/button';
import styled from 'styled-components';
import Monogram from '../../../_ui/monogram';
import {ReactComponent as PlayButton} from '../../../../assets/illustrations/play.svg';
import {ReactComponent as PlayArrowIcon} from '../../../../_icons/play_arrow.svg';
import {ROUTES} from '../../../../router/routes';
import {useNavigate} from 'react-router-dom';
import {DiagnosticAPIService, UserAPIService} from '../../../../_services/api';
import {useEffect, useState} from 'react';
import {APIListResponse} from '../../../../_services/api/_helpers/api-list-types';
import {APIDiagnostic} from '../../../../_services/api/_helpers/api-types';
import {DiagnosticCard} from '../diagnostics';
import {useDiagnostics} from '../../../../_hooks/diagnostic/use-diagnostics';
import {useQuote} from '../../../../_hooks/quote/use-quotes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  padding: 8px 20px;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  
  h1 {
    font-size: 24px;
  }
`;

const Bubble = styled.div`
  display: flex;
  padding: 0.8px 6px 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: 1px solid var(--Colors-Primary-Eerie-Black-P, #1D1D1B);
  background: var(--Secondary-Lemon-SLe, #E6F728);
  box-shadow: 0.5px 0.5px 0px 0px #000;
  position: absolute;
  left: -3px;
  top: -3px;
  color: var(--Text-primary, #1D1D1B);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 118.182% */
  letter-spacing: -0.2px;
`;

const Avatar = ({text, imageUrl, count}) => {
  const navigate = useNavigate();

  return (
    <div style={{position: 'relative'}}>
      <Monogram
        style={{cursor: 'pointer'}}
        onClick={() => navigate(ROUTES.PROFILES('me'))}
        $backgroundImageUrl={imageUrl}
      >
        {imageUrl ? null : text}
      </Monogram>
      <Bubble>{count}</Bubble>
    </div>
  )
};

const WordOfTheDay = styled.div`
  display: flex;
  padding: 16px 20px 20px 20px;

  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 0 20px;
  border-radius: 28px;
  border: 1px solid var(--Border-x-light, rgba(0, 0, 0, 0.03));
  background: var(--Primary-Gradient-PG-highlight, linear-gradient(45deg, #F0FDD3 0%, #FAFED4 100%));
`;

const Card = styled.div<{$theme?: 'dark' | 'light';}>`
  display: flex;
  padding: 20px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  flex: 1 0 0;
  border-radius: 32px;
  
  border: 1px solid var(--Colors-Primary-Eerie-Black-60, #DCDCD9);

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.2px;
  
  color: var(--Primary-Black-P, var(--Colors-Primary-Eerie-Black-P, #1D1D1B));
  background: var(--Background-surface-0, #FFF);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  
  ${props => props.$theme === 'dark' && `
    color: var(--Neutral-Grey-100, #FFF);
    background: var(--Background-Reverse, #1D1D1B);
    box-shadow: none;
  `}
`;

const CardsContainer = styled.div`
  display: flex;
  padding: 0px 20px;

  align-items: center;
  gap: 16px;
`;

const Caption = styled.div<{$theme?: 'dark' | 'light';}>`
  display: flex;
  justify-content: center;
  padding: 5px 12px 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: var(--Rounded, 99999px);
  background: ${props => props.$theme === 'dark' ? 'var(--Background-surface-0, #FFF)' : 'var(--Background-surface-20, #EDEDED)'};
  color: var(--Primary-Black-P, var(--Colors-Primary-Eerie-Black-P, #1D1D1B));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.2px;
  
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  padding: 0px 20px;
  box-sizing: border-box;
`;

const HistoryContainer = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: flex-end;
  //align-self: stretch;

  max-width: 100%;

`;

const HistoryCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  
  padding-left: 20px;
  padding-right: 20px;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const History = () => {
  const navigate = useNavigate();
  const [diagnostics, setDiagnostics] = useState<APIListResponse<APIDiagnostic>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const results = await DiagnosticAPIService.getDiagnostics({page_size: 5});
      setDiagnostics(results);
      setIsLoading(false);
    })();
  }, []);

  if (!diagnostics || diagnostics?.list?.length <= 0) {
    return null;
  }

  return (
    <HistoryContainer>
      <TitleContainer>
        <h2>Mon historique</h2>
        <span onClick={() => navigate(ROUTES.DIAGNOSTICS())}>
          Tout voir
        </span>
      </TitleContainer>
      <HistoryCardsContainer>
        {
          !isLoading && diagnostics?.list?.map((diagnostic, index) => (
            <DiagnosticCard
              key={index}
              diagnostic={diagnostic}
              style={{
                maxWidth: 220,
                maxHeight: 200,
                minWidth: 220,
                minHeight: 200,
              }}
              headerStyle={{
                flexDirection: 'column',
                gap: 8,
                alignItems: 'flex-start',
              }}
            />
          ))
        }
      </HistoryCardsContainer>
    </HistoryContainer>
  );
};

const HomePage = () => {
  const navigate = useNavigate();
  const {user} = useAuthContext();
  const {quote} = useQuote({id: 'today'});
  const {diagnostics} = useDiagnostics({
    sorting: [
      {
        name: 'diagnostic_versions_created_at',
        direction: 'desc',
      }
    ],
    filters: {
      diagnostic_versions_status: ['created', 'filtered']
    },
    page_size: 1,
  });

  const goToStripePortal = async () => {
    const response = await UserAPIService.getStripeCheckoutSessionUser(user.id, window.location.href);
    window.location.href = response.url;
  };

  return (
    <Container
      style={{maxHeight: 'calc(100svh - 76px)'}}
    >
      <Header>
        <div style={{flex: 1}}>
          <h1>Bonjour, {user.first_name} !</h1>
        </div>
        <Avatar
          text={user.first_name.charAt(0)}
          imageUrl={user.avatar?.url || ''}
          count={user.balance}
        />
      </Header>
      {user.plan !== 'premium' && (
        <Button
          style={{flex: 1, margin: '0 20px'}}
          $display="accent"
          onClick={goToStripePortal}
        >
          Passer Premium <PlayArrowIcon/>
        </Button>
      )}
      <Container
        style={{
          flex: 1,
          overflowY: 'scroll',
        }}
      >
        <CardsContainer>
          <Card $theme="dark">
            <div>Démarrer un diagnostic</div>
            <CaptionContainer>
              <div>
                <PlayButton
                  width={50}
                  style={{display: 'block'}}
                  onClick={() => navigate(ROUTES.DIAGNOSTICS('new'))}
                />
              </div>
              <Caption $theme="dark">10 min</Caption>
            </CaptionContainer>
          </Card>
          <Card>
            <div>Diagnostic en cours</div>
            <CaptionContainer>
              <PlayButton
                width={50}
                style={{
                  display: 'block',
                  filter: `grayscale(${diagnostics?.list?.[0]?.id ? 0 : 1})`,
                }}
                onClick={() => {
                  diagnostics?.list?.[0]?.id && navigate(ROUTES.DIAGNOSTICS(diagnostics.list[0].id));
                }}
              />
              <Caption>5 min</Caption>
            </CaptionContainer>
          </Card>
        </CardsContainer>

        {quote && (
          <WordOfTheDay>
            <span>La petite phrase d'Amélie</span>
            <div>{quote.text}</div>
          </WordOfTheDay>
        )}
        <History/>
      </Container>
    </Container>
  );
};

export default HomePage;